<template>
  <el-main>
    <div style="margin-bottom: 10px">
      <router-link to="/extension/pointsMall/pointClassAdd"><el-button type="primary" size="small">添加分类</el-button></router-link>
    </div>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="商品分类：">
        <el-input size="small" v-model="classify_name" placeholder="请输入搜索分类"></el-input>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-select v-model="is_show" @change="showValChange" size="small">
          <el-option v-for="item in showOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" @click="getTypeList" size="small">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="goodsClass" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
      <el-table-column prop="classify_name" label="分类名称" align="center"></el-table-column>
      <el-table-column prop="id" label="分类ID" align="center"></el-table-column>
      <el-table-column prop="address" label="组内权重" width="120">
        <template #header>
          <div class="edit">
            <span>组内权重</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template slot-scope="scope">
          <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)" v-model="scope.row.rank"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.is_show" @change="editChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
import config from '@/util/config';
export default {
  data() {
    return {
      showOptions: [
        {
          value: 0,
          label: '全部',
        },
        {
          value: 1,
          label: '否',
        },
        {
          value: 2,
          label: '是',
        },
      ],
      is_show: 0,
      classify_name: '',
      goodsClass: [],
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    editChange(row) {
      this.$axios
        .post(this.$api.integral.integralClass.edit, {
          id: row.id,
          classify_name: row.classify_name,
          rank: row.rank,
          is_show: row.is_show ? 1 : 0,
        })
        .then(res => {
          if (res.code == 0) {
            this.getTypeList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showValChange(val) {
      this.is_show = Number(val);
    },
    getTypeList() {
      this.$axios
        .post(this.$api.integral.integralClass.list, {
          is_show: this.is_show,
          classify_name: this.classify_name,
        })
        .then(res => {
          this.goodsClass = res.result.list;
        });
    },
    cancelSearch() {
      this.classify_name = '';
      this.is_show = 0;
      this.getTypeList();
    },
    //编辑分类
    edit(row) {
      this.$router.push(`/extension/pointsMall/editPointClass?id=${row.id}&is_show=${row.is_show}&classify_name=${row.classify_name}&rank=${row.rank}`);
    },
    //删除分类
    remove(row) {
      this.$confirm('确认删除此商品分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.integral.integralClass.del, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.getTypeList();
              this.$message({
                message: '删除成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;

  .el-table {
    flex: 1;

    /deep/ .el-input__inner {
      border-color: #fff;
    }

    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }

    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }

  .edit {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }

    i {
      font-size: 17px;
    }
  }
}

/deep/ .el-table__expand-icon--expanded {
  // 这是点击后的旋转角度
  transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
  .el-icon-arrow-right:before {
    //这是收起图标
    content: '\e723';
    font-size: 16px;
  }
}

/deep/ .el-table__expand-icon--expanded {
  .el-icon-arrow-right:before {
    // 这是展开图标
    content: '\e722';
    font-size: 16px;
  }
}
</style>
